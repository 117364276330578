.barcode-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;

  .modal-container {
    position: relative;
    height: 100%;
    width: 100%;

    .close-modal {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #383839;
      position: absolute;
      border-radius: 100%;
      right: 50px;
      top: 50px;
      z-index: 99;
      cursor: pointer;

      svg {
        fill: #FFF;
        width: 100%;
        max-width: 20px;
        path {
          fill: #FFF
        }
      }
    }
  }

}
