.product-loader{
  text-align: center;
}

.product-single-page {

  .product-single-header {
    margin: 0 0 25px 0;
  }

  .single-product-add-to-cart {
    font-weight: bold;
  }

  .product-images {
    margin: 0 auto 35px auto;
    max-width: 1400px;
    width: 100%;
  }

  .product-price {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .product-bottom {
    max-width: 800px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .product-meta {
      color: #383839;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 0.9rem;

      > div {
        margin: 0 0 10px 0;
      }

      .product-sizes {
        .sizes-list-component {
          justify-content: flex-start;
        }
      }

      .product-code {
        font-size: 1.2rem;
        font-weight: bold;
      }

      .single-product-add-to-cart {
        font-size: 1.2rem;
        max-width: 250px;
        width: 100%;
        display: block;
        background: #383839;
        color: #FFF;
      }

    }

    .product-controls {
      max-width: 250px;
      width: 100%;

      svg {
        max-width: 22px;
        width: 100%;
        display: block;
      }

      .vamp-button {
        margin: 0 0 15px 0;
        padding: 12px;
        font-size: 1.1rem;
      }
    }
  }

  .site-global-bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-code, .product-price {
      @media (max-width: 768px) {
        display: none !important;
      }
    }

    @media (max-width: 640px) {
      flex-direction: column;

      .product-name {
        border-right: 0 !important;
        margin: 0 0 5px 0;
        text-align: center;
      }
    }

    .product-info {
      color: #FFF;
      display: flex;
      align-items: center;
      > div {
        padding: 0 8px;
        border-right: 2px solid #FFFFFF;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .product-controls {
      display: flex;
      align-items: center;
      color: #FFF;

      svg {
        max-width: 35px;
        width: 100%;
        height: auto;
      }

      > div {
        margin: 0 0 0 20px;
      }

      .wishlist-button {
        position: static;

        &.is-in-wishlist {
          svg {
            fill: #FFF !important;
          }
        }

        svg {
          fill: transparent;
          stroke: #FFFFFF;
        }

      }

      .product-quickview-button {
        cursor: pointer;
        svg {
          fill: #FFF;
        }
      }
    }
  }
}
