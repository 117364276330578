.downloads-list{
  list-style: none;
  padding: 0 0 0 0;

  li {
    display: flex;
    align-items: center;
  }

  svg {
    max-width: 15px;
    height: auto;
    width: 100%;
    margin: 0 10px 0 0;
  }

}