.customers-access-table {
  overflow-x: auto;
  display: block;
  width: 100%;

  td {
    min-width: 150px;
    border-bottom: 1px solid #383839;
    border-right: 1px solid #383839;
    text-align: center;
  }

  th {
    text-align: center;
  }

  tr {
    &:nth-child(odd) {
      td {
        background: #38383912;
      }
    }
  }

}

.no-customers-found-msg {
  text-align: center;
  font-size: 0.875rem;
}

.customers-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  box-sizing: border-box;

  .customer-list-item {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 15px;
    box-sizing: border-box;

    @media (max-width: 860px) {
      width: 50%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }

  }
}
