.form-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-form-container {

  margin: 0 0 50px 0;

  header {
    background: #FFF;
    color: #383839;
    border: 0;
    border-bottom: 1px solid #383839;
    padding: 15px 0;
  }
}
