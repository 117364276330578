.search-error {
  font-size: 14px;
  color: red;
  position: absolute;
}

.search-results-list {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 24px rgba(0,0,0,.16);
  z-index: 99;
  list-style: none;
  max-height: 350px;
  overflow: auto;
}
