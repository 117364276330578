.checkout-error-response{
  padding: 5px;
  background: #f98a8a;
  font-size: 0.875rem;

  p {
    margin: 0 0 5px 0;
  }

  li {
    strong {
      margin: 0 5px 0 0;
    }
  }
}