.info-table {

  table, tbody {
    width: 100%;
  }

  th {
    background: #383839;
    color: #FFF;
    font-size: 14px;
  }

  td {
    font-size: 15px;
  }

  th,td {
    padding: 6px;
    border-bottom: 1px solid #383839;
  }

  .table-input-filter {
    margin: 5px 0 0 0;
    height: auto;
    font-size: 0.875rem;
    padding: 5px;
    border-radius: 0;
    border: 0;
    line-height: 1.1;
  }

  .pagination{
    margin: 15px 0;

    > span {
      margin: 0 15px;
    }

    button {
      background: #383839;
      color: #FFF;
      border: 0;
      margin: 0 5px 0 0;
    }
  }

}