.customers-access-page {
  width: 100%;

  .panel-top-controls {
    display: flex;
    flex-direction: column;

    .panel-search {
      input {
        width: 100%;
        box-sizing: border-box;
      }
    }

    .customer-access-panel-filters {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 15px 0;
      font-size: 0.9rem;

      @media (max-width: 768px) {
        flex-wrap: wrap;

        li {
          width: 50%;
          box-sizing: border-box;
          margin: 0 0 15px 0 !important;
        }

      }

      li {
        margin: 0 15px 0 0;
        padding: 0 5px 0 0;

        span {
          margin: 0 5px 0 0;
        }

      }
    }
  }

  .customers-list {
    flex-direction: column;

    .customer-list-item {
      width: 100%;
    }
  }
}
