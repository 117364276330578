.bottom-navigation-arrows {
  display: flex;
  justify-content: center;

  .next-navigation-item, .prev-navigation-item {
    margin: 0 15px;
    padding: 0 5px;
    cursor: pointer;

    svg {
      max-width: 30px;
      width: 100%;
      fill: #FFF;
    }
  }

  .prev-navigation-item {
    svg {
      transform: rotate(180deg);
    }
  }

}
