.panel-field {
  svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    margin: 0 0 0 10px;
  }

  .edit-input-form {
    input {
      padding: 4px;
    }

    button {
      background: #000;
      color: #FFF;
      padding: 5px 10px;
      border: 0;
      border-radius: 0;
      margin: 0 0 0 5px;
      cursor: pointer;
    }
  }
}
