.multigrid-controls {
  display: flex;
  justify-content: flex-end;

  .vamp-button {
    padding: 15px;
  }

  .reset-grid-button {
    max-width: 208px;
    width: 100%;
    height: 48px;
    margin-right: 25px;
  }

  .add-to-cart-button {
    max-width: 208px;
    width: 100%;
    height: 48px;
  }

}
