.product-item {
  position: relative;
}

.wishlist-button {

  position: absolute;
  right: 5px;
  top: 5px;

  button {
    background: none;
    border: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  svg {
    max-width: 35px;
    width: 100%;
    height: auto;

    @media (max-width: 640px) {
      max-width: 30px;
    }

  }

  &.icon-heart {
    svg {
      fill: #FFF;
      stroke-width: 10px;
      stroke: #000000;
    }

    &.is-in-wishlist {
      svg {
        fill: #000 !important;
        stroke-width: 0;
      }
    }
  }
}
