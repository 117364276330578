.customer-accept-access-page {
  text-align: center;
  max-width: 400px;
  margin: auto;

  .grant-access-form-container {

    .extra-notice {
      font-size: 0.875rem;
    }

    .form-errors {
      color: red;
      margin: 10px 0;
    }

    .form-input {
      margin: 0 0 15px 0;

      label {
        margin: 0 0 5px 0;
        display: block;
        width: 100%;
        text-align: left;
        font-size: 0.9rem;
      }

      input {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 8px;
      }

      button {
        background: #000;
        color: #FFF;
        border: 0;
        padding: 8px 15px;
      }
    }
  }
}
