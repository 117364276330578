video {
  max-width: 400px;
  height: auto;
}

div {
}

.product-price-container {
  .discount-bubble {
    border: 1px solid #0a0a0a;
    padding: 3px;
    margin-left: 15px;
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    font-weight: bold;
  }
}
