.colors-list-component {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;

    .selectedColor {
      border: 1px solid #383839;
      padding-left: 8px;
      border-radius: 15px;
      padding-right: 8px;
      margin-left: 2px;
      margin-right: 2px;
    }

    li {
      display: flex;
      align-items: center;
      padding: 0 5px 0 0;
      cursor: pointer;
      margin-left: 8px;
      margin-right: 8px;
    }

    .color-hex {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 5px 0 0;
      display: inline-block;
      border: 1px solid rgba(217,217,217,.8);
    }
  }
}
