.form-section-title {
  background: #383839;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  color: #fff;
  box-sizing: border-box;
}

.form-section-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0 15px;
}
