.family-item {
  padding: 15px;
  color: #383839;
  text-align: center;
  cursor: pointer;
  position: relative;

  .web-only-svg {
    position: absolute;
    top: 19px;
    left: 25px;
    width: 90px;
    @media (max-width: 600px) {
      width: 79px;
      left: 16px;
      top: 12px
    }
    @media (max-width: 460px) {
      width: 64px;
      left: 14px;
      top: 10px;
    }
  }

  img {
    width: 100%;
  }

  .family-friendly-name {
    padding: 14px 0;
  }
}
