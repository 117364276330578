.product-miniature {
  box-sizing: border-box;
  transition: box-shadow 0.3s ease-in-out;
  background: #FFF;

  .sample-title {
    font-size: .9rem;
    color: #383839;
  }

  &:hover {
    box-shadow: 3px 4px 18px rgba(0,0,0,.15);
  }

  &.fancy-miniature {
    cursor: pointer;
  }

  svg {
    max-width: 35px;
    width: 100%;
    fill: #383839;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .image-box {
    img {
      width: auto;
      max-height: 90vh !important;
    }
  }

  .meta {
    padding: 10px;

    .title {
      text-align: center;
      font-size: .8125rem;
      color: #000;
      text-decoration: none;
      margin-bottom: 5px;
      min-height: 40px;
    }

    .code{
      text-align: center;
      color: #383839;
      font-size: .9rem;
      font-weight: 700;
    }

    .miniature-input-controls {
      margin-left: 10px;

      svg {
        margin: 0 5px;
      }
    }

    .qty-input{
      text-align: center;
      padding: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        font-size: .876rem;
        margin-right: 5px;
        margin-bottom: 0;
      }

      input {
        border: 1px solid #d9d9d9;
        padding: 5px;
        text-align: center;
        width: 40px;
        height: 25px;
        font-size: .875rem;
      }
    }
  }

  .sample-item-controls {
    color: black;
    background-color: #f1f2f6;
    text-align: center;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    padding: 6px;
    font-size: 1.1rem;

    &.remove {
      background: #e84848;
    }

  }
}

.product-miniature-sample {
  padding-bottom: 30px;
}
