.checkout-page {
  .checkout-error {
    text-align: center;
    color: red;
    font-size: 1.1rem;
  }

  .over-shipping-notice {
    text-align: center;
    padding: 15px 0;
    font-weight: bold;
  }

  .points-top-notice {
    text-align: center;
    background: #383839;
    color: #FFF;
    padding: 15px;

    h3 {
      font-size: 1rem;
      margin: 0 0 5px 0;
    }

    h4 {
      font-size: 0.875rem;
      margin: 0;
    }
  }

  .customer-points-intro{
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    background: #383839;
    color: #FFF;
    padding: 10px;

    p {
      margin: 0;
      width: auto !important;
    }

    svg {
      max-width: 25px;
      height: auto;
      width: 100%;
      margin: 0 0 0 8px;
      fill: #FFF;
    }
  }

}

.hidden {
  display: none !important;
}

.checkout-form {
  .form-group {
    box-sizing: border-box;
    display: flex;
    margin: 0 0 5px 0;

    &.error {
      input, select {
        border: 2px solid red
      }
    }

    @media (max-width: 600px) {
      margin-bottom: 15px;

      span {
        display: block;
      }
    }
  }

  .value {
    width: 100%;
  }

  .label {
    max-width: 150px;
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;

    &.required {
      &:before {
        content: '*';
        color: red;
      }
    }

  }

  select {
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 8px;
  }

  .order-review {
    .form-group {
      display: flex;
      justify-content: space-between;

      .value {
        font-size: 1.1rem;
        font-weight: bold;
        color: #383839;
      }
    }
  }

  .extra-order-data {

    .checkout-section-content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;

      .form-group {
        padding: 0 15px;
        width: 50%;
        margin-bottom: 15px;

        @media (max-width: 640px) {
          width: 100%;
        }

        &.textarea-group {
          width: 100%;
          flex-direction: column;

          .label, .value {
            width: 100%;
            display: block;

            textarea {
              width: 100%;
              padding: 8px;
              box-sizing: border-box;
            }
          }

          .vamp-button {
            margin: auto;

            &:hover {
              background: #FFF !important;
            }

            svg {
              margin: auto;
              max-width: 25px;
              width: 100%;
              height: auto;
            }

          }
        }
      }
    }
  }

  .customer-points {
    width: 100% !important;
    display: block;
    p {
      width: 100%;
    }
  }

}

.checkout-submit {
  display: flex;
  align-self: center;
  justify-content: center;

  .vamp-button {
    max-width: 240px;
    width: 100%;
    background: #383839;
    color: #FFF;
    font-size: 1.4rem;
  }
}
