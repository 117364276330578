.welcome-page {
  .welcome-menu-list {
    max-width: 1000px;
    margin: 100px auto;
    display: flex;
    justify-content: space-between;
    list-style: none;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .list-item-element {
      text-align: center;
      box-shadow: 0 0 10px #383839;
      max-width: 300px;
      width: 100%;
      height: 100%;
      margin: auto;

      &.element-no-style {
        box-shadow: none;
      }
    }

    .label {
      font-size: 1.1rem;
      padding: 15px 5px;
      text-decoration: none;
      color: #383839;
      min-height: 83px;
      align-content: space-evenly;
    }

    > a {
      padding: 25px 15px;

      svg {
        max-width: 100px;
        width: 100%;
        height: auto;
      }

      img {
        width: 100%;
        height: auto;

        @media (max-width: 768px) {
          max-width: 400px;
        }
      }

      &:hover {
        text-decoration: none;
      }

    }

  }
}