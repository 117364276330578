.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: -webkit-fill-available;
  //height: 100vh;

  input {
    box-sizing: border-box;
  }

  .login-form-error {
    text-align: center;
    font-weight: bold;
    color: red;
  }

  .login-logo {
    text-align: center;
    margin-bottom: 25px;

    svg {
      max-width: 220px;
      height: auto;
      width: 100%;
    }

  }

  .login-banner {
    @media(max-width: 600px) {
      display: none;
    }
  }

  .welcome-notice {
    font-size: 16px;
    margin-bottom: 35px;
    p {
      margin: 0 0 5px 0;
    }
  }

  .login-form {
    max-width: 500px;
    width: 100%;

    .password-form-group {

      .input-container {
        position: relative;
        width: 100%;

        input {
          width: 100%;
          padding-right: 45px;
        }
      }

      .show-password-toggle {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      svg {
        max-width: 25px;
        width: 100%;
        height: auto;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        text-transform: lowercase;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }

    .submit-button {
      margin: 15px 0 0 0;
      background: #383839;
      color: #FFF;
      font-size: 18px;
      padding: 12px;
      cursor: pointer;
    }
  }

  .reauth-pin-container {
    text-align: center;

    p {
      font-size: 0.975rem;
      font-weight: bold;
    }

  }

  input {
    padding: 8px;
    border: 1px solid #DDD;
  }

}
