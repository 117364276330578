.customer-access-table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customers-list-container {
  position: relative;
}

.access-list-item {

  .customer-name {
    padding: 40px 0;
    background: #383839 !important;

    .value {
      position: absolute;
      left: 0;
      max-width: 150px;
      width: 100%;
      border-right: 2px solid #383839;
      padding: 20px;
      transform: translateY(-40px);
      background: #383839;
      color: #FFF;
    }
  }

  .customer-item-box {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    > div {
      padding: 5px;
      margin: 0 15px 0 0;
      text-align: left;

      .value {
        text-align: center;

        @media (max-width: 768px) {
          text-align: left;
        }
      }

      @media (max-width: 768px) {
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 10px;
        margin: 0 0 15px 0;
      }

      @media (max-width: 580px) {
        width: 50%;
      }

      label {
        margin: 0 0 5px 0;
        font-weight: bold;
        color: #383839;
        height: 20px;
        display: inline-block;
        border-bottom: 1px solid #383839;
        padding: 5px 0;
        text-align: left;
      }

      &.customer-seasons {
        width: 100%;
      }
    }
  }

  .edit-field-container {
    .label {
      display: none;
    }

    svg {
      max-width: 20px;
      width: 100%;
      height: auto;
      margin: 0 0 0 10px;
    }

    .edit-input-form {
      input {
        padding: 4px;
      }

      button {
        background: #000;
        color: #FFF;
        padding: 5px 10px;
        border: 0;
        border-radius: 0;
        margin: 0 0 0 5px;
        cursor: pointer;
      }
    }
  }

  .yes-value {
    background: green;
    color: #FFF;
    display: inline-block;
  }

  .no-value {
    background: red;
    color: #FFF;
    display: inline-block;
  }

  .value {
    span {
      padding: 4px;
    }
  }


  .customer-send-access {
    width: auto !important;
    .value {
      display: flex;

      input {
        padding: 6px;
        flex: 1;
      }
    }
  }
}


