.cart-mutation-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  background: #FFF;
  box-shadow: 0 0 10px #383839;

  .message-text{
    text-align: center;
    p {
      text-align: center;
    }
  }

  .outer-wrap {
    position: relative;
    padding: 25px;
  }

  .inner-container{
    padding: 10px;
  }

  .close-popup{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    svg {
      max-width: 18px;
      width: 100%;
      height: auto;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background: #383839;
      color: #FFF;
      border: 0;
      padding: 10px;
      margin: 10px;
    }
  }
}