.archive-header {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .archive-header-delivery-title {
    color: green;
  }

  .archive-header-center {
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    text-align: center;
    color: #383839;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
  }

  .pangination-info {
    font-size: 0.875rem;

    .active {
      font-weight: bold;
    }
  }

}
