.checkout-section {
  margin-bottom: 35px;
}

.checkout-section-header {
  background: #fff;
  padding: 10px 15px;
  font-size: 1rem;
  color: #383839;
  border-bottom: 1px solid #383839;
  display: flex;
  align-items: center;

  .section-number {
    border: 1px solid #383839;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-title {
    margin-left: 25px;
    font-weight: 700;
  }
}

.checkout-section-content {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 0.95rem;

  .label {
    color: #383839;
    min-width: 80px;
  }

}
