.customer-verification-page {
  .error-messages {
    span{
      display: block;
      font-size: 0.875rem;
      color: red;
    }
  }

  .customer-verification-form {
    max-width: 600px;
    margin: auto;
    text-align: center;

    input {
      padding: 5px;
    }

  }

  .request-new-pin{
    margin: 25px 0;
    font-size: 0.875rem;
    button {
      margin: 10px 0 0 0;
      padding: 5px;
      border: 0;
      background: #0a0a0a;
      color: #FFF;
    }
  }

}