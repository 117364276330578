.family-archive-page {
  header {
    font-size: 0.7rem;
    margin: 0 0 20px 0;
    color: #383839;

    .title-container {
      text-align: center;

      .title {
        font-size: 1.6rem;
      }
    }

    .header-extras {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .banner-header {
      margin: 40px 0;
      img{
        width: 100%;
      }

      .banner-desktop {
        @media(min-width: 960px) {
          display: block;
        }
        display: none;
      }

      .banner-mobile {
        @media(max-width: 960px) {
          display: block;
        }
        display: none;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .left-container {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media (max-width: 768px) {
        width: 50%;
        order: 2;
      }

      @media (max-width: 560px) {
        width: 100%;
      }

      a {
        color: #919191;
        background: none;
        border: 0;
        cursor: pointer;
        text-decoration: none;
      }

      .back-to-season {
        margin: 0 0 10px 0;
        font-size: 1.1rem;
      }

      .colors-list {
        display: flex;
        flex-direction: column;

        @media (max-width: 560px) {
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
        }

        li {
          display: flex;
          align-items: center;
          margin: 0 0 5px 0;

          @media (max-width: 560px) {
            margin: 0 5px 0 0;
          }

          &:last-child {
            margin: 0;
          }
        }

        .color-hex {
          width: 13px;
          height: 13px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 8px;
          border: 1px solid rgba(217,217,217,.8);
        }
      }
    }

    .right-container {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media (max-width: 768px) {
        width: 50%;
        order: 3;
      }

      @media (max-width: 560px) {
        width: 100%;
        align-items: flex-end;
      }

      .family-composition {
        margin: 0 0 8px 0;
      }

      .sizes-list {
        display: flex;

        li {
          padding: 0 4px;
        }
      }
    }

    .header-center {
      width: 50%;
      padding: 15px;
      justify-content: center;
      display: flex;

      @media (max-width: 768px) {
        width: 100%;
        order: 1;
        margin: 5px 0;
      }

      .title {
        margin: 0;
      }
    }
  }
}
