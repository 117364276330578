.modal-component {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21, 16, 16, 0.6);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .modal-container {
    box-sizing: border-box;
    position: relative;
    max-width: 600px;
    width: 100%;
    margin: auto;
    background: #FFF;
    padding: 25px;

    header {
      margin: 0 0 25px 0;

      h1 {
        margin: 0;
        font-size: 1.2rem;
        color: #383839;
      }
    }

    .customers-list {
      width: 100%;
      margin: 0;

      ul {
        margin: 0;
        padding: 0;
        width: 100%;
        list-style: none;
        box-sizing: border-box;

        li {
          margin: 0 0 15px 0;
          display: flex;
          justify-content: space-between;
        }

      }
    }

    .modalClose {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      background: #383839;
      color: #FFF;
      border-radius: 50%;
      width: 35px;
      height: 35px;

      svg {
        padding: 10px;
        width: 100%;
        max-width: 25px;
        fill: #FFF;

        path {
          fill: #FFF;
        }

      }
    }
  }
}
