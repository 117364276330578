.account-sidebar-item {
  display: block;
  text-align: center;

  svg {
    max-height: 25px;
    width: auto;
  }

  a, div, span {
    text-decoration: none;
    font-size: 0.9rem;
    color: #383839;
    text-align: center;
    display: block;
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &:hover {
    a, span {
      //color: #FFF;
      text-decoration: none;
      //background: #383839;
    }
  }
}
