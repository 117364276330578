.archive-title{
  .page-title {
    text-align: center;
    padding: 0 0 25px 0;
    margin: 0;

    h1 {
      color: #383839;
      font-size: 1.6rem;
      margin: 0;
    }
  }
}