.family-filters {
  margin: 25px 0;

  .family-filter-element {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      max-width: 20px;
      height: auto;
      width: 100%;
      margin-right: 5px;
    }
  }

  .web-only-label {
    position: absolute;
    top: 28px;
    width: 92px;
    text-align: center;
    color: #d47eac;
    font-size: 12px;
  }

  .vamp-button {
    display: none;
  }

  @media (max-width: 768px) {
    .vamp-button {
      max-width: 150px;
      display: block;
    }
  }

  ul {
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      color: #383839;
      font-size: 1rem;
      padding: 10px 15px;
      cursor: pointer;

      @media (max-width: 992px) {
        padding: 10px;
        font-size: 0.875rem;
      }

      &.active {
        color: #000;
        font-weight: 700;
        border: 1px solid #000;

        svg {
          fill: #FFF;
        }
      }
    }

    @media (max-width: 768px) {
      display: none;
      background: #383839;

      &.show-filters {
        display: block;
      }

      li {
        color: #FFF;
        border: 1px solid transparent;

        &.active {
          background: #FFF;
          color: #383839;
          border: 1px solid #383839;
        }

      }
    }

  }
}
