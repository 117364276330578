.panel-filters {

  .date-filters {
    display: flex;
    margin: 0 0 15px 0;
    > .form-group {
      margin: 0 10px 0 0;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    font-size: 13px;

    label {
      font-weight: bold;
      margin: 0 0 5px 0;
    }

    input {
      border: 1px solid #b9b9b9;
      padding: 5px;
    }

    .vamp-button{
      display: inline-block;
      width: auto;
      background: #383839;
      color: #FFF;
    }
  }
}

.infos-panel{
  width: 100%;
}