.address-list-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background: #383839;
  color: #FFF;
  padding: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    .address-label {
      margin: 0 0 15px 0
    }

  }

  .address-controls {
    display: flex;
    align-items: center;
  }

  span.address-control {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 0 15px;

    @media (max-width: 600px) {
      margin: 0;
    }

  }

  svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    fill: #FFF;
    margin: 0 0 0 10px;
  }
}
