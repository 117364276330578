#scroll-to-top-component{
  position: fixed;
  right: 25px;
  bottom: 85px;
  z-index: 9999;
  cursor: pointer;
  display: none;

  &.show-element {
    display: block;
  }

  .scroll-to-top-container {
    border-radius: 50%;
    background: #383839;
    padding: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    fill: #FFF;
    max-width: 100%;
    height: auto;
    width: 100%;
  }
}