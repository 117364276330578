

.family-products-container {
  .prev-family, .next-family {
    position: fixed;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);

    svg {
      max-width: 150px;
      height: auto;
      fill: #383839;
    }
  }

  .prev-family {
    left: 40px;
  }

  .next-family {
    right: 40px;

    svg {
      transform: rotate(180deg);
    }
  }
}
