.coming-soon-header-container {
  text-align: center;
  padding: 15px 0;

  .coming-soon-header {
    margin: 0 0 15px 0;
  }

  p {
    background: #FFF;
    color: #383839;
    border: 1px solid #383839;
    padding: 5px 15px;
    font-size: 1.3rem;
    display: inline-block;
    margin: auto;
  }

}

.banner-desktop-dynamic-banner {
  @media(min-width: 960px) {
    display: block;
  }
  display: none;
}
.banner-mobile-dynamic-banner {
  @media(max-width: 960px) {
    display: block;
  }
  display: none;
}


.banner-header {
  margin: 40px 0;
  img{
    width: 100%;
  }

  .banner-desktop {
    @media(min-width: 960px) {
      display: block;
    }
    display: none;
  }

  .banner-mobile {
    @media(max-width: 960px) {
      display: block;
    }
    display: none;
  }
}