.variation-stock-tooltip {
  p {
    margin: 0 0 2px 0;
  }
}

.size-cell-container, .color-cell-container {
  text-align: center;
}

.size-stock {
  font-size: 0.8rem;
  padding: 5px 0 0 0;
}

.selectedColor-cell {
  border: 1px solid #383839 !important;
  border-radius: 28px !important;
}

.color-cell-container {
  width: 135px;
  font-size: 0.8rem;
  margin: auto;
  padding: 0 5px;
  cursor: pointer;

  .color-hex {
    width: 35px;
    height: 15px;
    margin: 5px auto;
    border: 1px solid rgba(217,217,217,.8);
  }

  .color-name-box {
    .color-code {
      margin-right: 5px;
    }
    span {
      display: block;
      text-transform: capitalize;
    }
  }

}

.size-cell-container {

  padding: 15px 0;

  input {
    width: 70px;
    height: 40px;
    border: 1px solid #e1e1e1;
    padding: 5px;
    text-align: center;
    font-size: .875rem;
    transition: border-color .3s ease-in-out;
    box-sizing: border-box;

    &.availability-unavailable {
      border: 2px solid red;
    }

    &.availability-now {
      border: 2px solid green;
    }

    &.availability-5days {
      border: 2px solid yellow;
    }

    &.availability-30days {
      border: 2px solid brown;
    }

    &.input-focused {
      border: 2px solid #383839;
    }

  }
}

.cart-input-simone {
  width: 70px;
  height: 40px;
  border: 1px solid #e1e1e1;
  padding: 5px;
  text-align: center;
  font-size: .875rem;
  transition: border-color .3s ease-in-out;
  box-sizing: border-box;
  &.availability-unavailable {
    border: 2px solid red;
  }
  &.availability-now {
    border: 2px solid green;
  }
  &.availability-5days {
    border: 2px solid yellow;
  }
  &.availability-30days {
    border: 2px solid brown;
  }
  &.input-focused {
    border: 2px solid #383839;
  }
}

.row-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 0;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
  }

  svg {
    width: 28px;
    height: 28px;
    cursor: pointer;
    fill: #383839;
  }
}

.line-totals-container {
  text-align: center;
  font-size: .875rem;
}
