.wishlist-page {
  header.page-header {
    margin: 0 0 35px 0;
    text-align: center;
  }

  .page-title {
    color: #383839;
    font-size: 2.1rem;
    margin: 0;
  }
}
