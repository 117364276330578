.customer-statements {
  width: 100%;
  .panel-filters {

    .right-container {

      >* {
        margin: 0 0 15px 0;
      }

      .infos-print-pagination {

        h4 {
          font-size: 0.9rem;

        }

        p {
          font-size: 1.1rem;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin: 0 10px 0 0;
            padding: 5px;
            cursor: pointer;
            display: inline-block;
            width: 35px;
            text-align: center;
            font-size: 14px;
            background: #383839;
            color: #fff;
          }
        }
      }

    }

    .left-container {
      display: block;
      margin: 0 0 15px 0;

      > .form-group {
        margin: 0 15px 0 0;
      }
    }
  }

  .form-group {
    label {
      margin: 0 10px 0 0;
    }
  }

  .active-page{
    background: #3f4c7a !important;
  }

}