.season-archive-header {
  margin: 0 0 25px 0;

  .title-container {
    text-align: center;
  }

  .header-extras {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-products-control {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;

    .action-button {
      font-size: 0.8rem;
    }
  }

  .page-title {
    color: #383839;
    font-size: 1.6rem;
    margin: 0;
  }

  .right-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top-products-link {
    background: #383839;
    color: #FFF;
    padding: 5px 10px;
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    .page-title {
      width: 100%;
      order: 1;
      text-align: center;
      margin: 0 0 15px 0;
    }

    .left-container {
      order: 2;
      width: 50%;
      text-align: left;
    }

    .right-container {
      order: 3;
      width: 50%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }
}
