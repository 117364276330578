.panel-menu {

  display: flex;
  align-items: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      background: #383839;
      color: #FFF;
      padding: 8px;
      text-decoration: none;
      font-size: 0.875rem;
      font-weight: bold;
      display: inline-block;
    }

  }
}
