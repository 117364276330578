.site-global-bottom-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #383839;
  padding: 10px;
  box-sizing: border-box;
  z-index: 9;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

}
