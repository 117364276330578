.tablet-controls-component {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  width: 100%;

  .table-controls-container {
    padding: 10px;
    background: #383839;
    display: flex;
    justify-content: center;

    .tablet-control-element {
      display: inline-block;
      margin: 0 15px;
      cursor: pointer;
    }
  }

  svg {
    max-width: 45px;
    height: auto;
    width: 100%;
    fill: #FFF;
  }
}
