.customer-settings {

  border-top: 1px solid #0a0a0a;

  .form-row {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    .label {
      margin: 0 5px 0 0;
    }

    input {
      margin: 0 0 0 0;
    }
  }
}

.customer-info-panel-points-notice {
  text-align: center;
  background: #383839;
  color: #FFF;
  font-size: 1rem;
  padding: 5px;
  margin: 0 0 5px 0;

  p {
    margin: 0 0 5px 0;
  }

}

.edit-field-container {
  display: flex;
  flex-direction: column;
}

.account-balances-container {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    padding: 0 10px;

    &.account-balance {
      .label {
        color: #c16780;
      }
    }

    .label {
      padding: 0 5px 0 0;
    }

  }

}

.salesmen-row {
  h5, ul {
    font-size: 0.875rem;
  }

  h5 {
    font-weight: bold;
  }
}

.personal-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px;

  .panel-field {
    width: 50%;
    padding: 0 15px;

    @media(max-width: 600px) {
      width: 100%
    }
  }
}
