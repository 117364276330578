.cart-summary-widget {
  display: flex;
  align-items: center;
  font-size: 0.85rem;

  .cart-count-item {
    box-sizing: border-box;
  }

  .cart-count {
    padding: 0 15px;
    box-sizing: border-box;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    .cart-count-item {
      width: 50%;
      text-align: left;
      padding: 0 15px;
      display: inline-block;
      margin: 0 0 5px 0;
    }

    svg {
      display: none;
    }
  }

}
