.order-details-btn {
  a {
    text-decoration: none;
  }
}

.orders-table-component {
  width: 100%;

  .order-edit-button {
    cursor: pointer;
  }

  .orders-table {
    td, th {
      text-align: left;
    }
  }

}

.orders-pdf-link {
  svg {
    max-width: 35px;
    width: 100%;
    height: auto;
  }
}