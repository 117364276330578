.product-filters {
  font-size: 0.9rem;
  margin: 0 0 25px 0;



  .view-per-line {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      display: none !important;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 0 5px;
      display: flex;

      li {
        margin: 0 5px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }

  svg {
    width: 15px;
    height: auto;
  }

  .products-found {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      display: none !important;
    }

  }

  .product-search {
    max-width: 350px;
    margin: auto;
    position: relative;

    .search-icon {
      position: absolute;
      left: 5px;
      top: 9px;
      z-index: 2;
    }

    .clear-icon {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 2;
      cursor: pointer;
      padding: 5px;
    }

    .search-icon, .clear-icon {
      display: flex;
      align-items: center;
    }

    input {
      display: block;
      padding: 8px 40px 8px 45px;
      border: 0;
      border-bottom: 1px solid #383839;
      box-sizing: border-box;
      font-size: 1rem;
      width: 100%;
    }
  }
}

.site-global-bottom-bar {
  .product-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    margin: 0;

    &.has-bottom-navigation {
      @media(max-width: 600px) {
       .product-search {
         display: none;
       }
      }
    }

   .right-section {
     display: flex;
     box-sizing: border-box;

     > div {
       margin: 0 0 0 15px;
     }
   }
  }
}
