.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #d9d9d9;
  position: relative;
  margin-bottom: 25px;
  box-sizing: border-box;

  @media (max-width: 840px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .cart-actions-container {
    @media (max-width: 840px) {
      margin-bottom: 15px;
    }
  }

  .search-container {
    max-width: 500px;
    width: 100%;

    @media (max-width: 840px) {
      max-width: 100%;
    }
  }

  svg {
    width: 25px;
    height: auto;
  }

}

.cart-header-sticky {
  min-height: 80px;

  .cart-count-item {
    display: none !important;
  }

  .cart-header {
    position: fixed;
    z-index: 9;
    width: 100%;
    top: 0;
    background: #FFF;
    left: 0;
    padding: 10px;
  }
}
