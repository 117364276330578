.multigrid-header-row {
  th {
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    color: #000;
    font-weight: 300;
    text-align: center;
    padding: 25px 0;
    font-size: .813rem;
    box-sizing: border-box;

    &:last-child {
      border: 0;
    }
  }

  .table-size-color-label {
    position: relative;

    .size-th-label {
      position: absolute;
      right: 15%;
      top: 15%;
      font-weight: 700;
    }

    .color-th-label {
      position: absolute;
      left: 15%;
      bottom: 15%;
      font-weight: 700;
    }

    .line {
      left: 0;
      top: 0;
      width: 115%;
      height: 55%;
      transform: rotate(29deg);
      border-bottom: 1px solid #dedede;
      position: absolute;
    }
  }
}
