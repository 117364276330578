.panel-top-controls {
  padding: 15px;
  border: 1px solid #d9d9d9;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 600px) {
    flex-wrap: wrap;
  }

  .panel-search {
    padding: 5px;
    position: relative;

    @media(max-width: 600px) {
      margin-bottom: 15px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      max-width: 20px;
      height: auto;
    }

    input {
      font-size: .935rem;
      border: 0;
      border-bottom: 1px solid #d9d9d9;
      padding: 10px 10px 10px 30px;
    }
  }
}
