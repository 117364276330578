.panel {
  width: 100%;
}

.salesman-infos {
  width: 100%;
}

.account-panel-error {
  color: red;
}

.panel-body {
  padding: 15px;

  .panel-row {
    margin-bottom: 25px;

    header {
      color: #383839;
      border-bottom: 1px solid #383839;
      width: 100%;
      display: block;
      margin-bottom: 15px;
      font-weight: bold;
    }

    .panel-field {
      display: flex;
      flex-direction: column;
      margin: 0 0 10px 0;
      box-sizing: border-box;
      justify-content: center;

      label {
        font-size: .875rem;
        color: #383839;
        border-bottom: 1px solid #d9d9d9;
        margin: 0 0 5px 0;
      }

    }

  }
}
