.date-ranges{
  margin: 15px 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      margin: 0 15px 0 0;
      font-size: 0.9rem;
      cursor: pointer;
      font-weight: bold;
      border: 1px solid;
      padding: 0 10px 0 10px;
    }
  }
}
