.cart-row {
  align-content: flex-start;
  margin-left: 0px;
  //min-width: 100px;
  text-align: left;
  & > .color-hex {
    margin-left: 0px;
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  & > .color-name-box {
    display: block;
    font-size: 14px;
    vertical-align: top;
    margin-top: 0px;
    margin-left: 5px;
  }
  &.cart-row-edit {
    div {
      background: #383839;
      color: #FFF;
      padding: 5px 0;
      cursor: pointer;
      max-width: 50px;
      width: 100%;
      margin: auto;
    }
  }

  .cart-image-sku-container {
    align-items: center;

    & > .color-cell-container {
      width: 100%;
      & > .color-name-box > .color-name-box {
        display: inline-block;
      }
      & > .color-hex {
        margin-left: 0px;
        width: 24px;
        height: 24px;
        display: block !important;
      }
    }

    @media(max-width: 1200px) {
      min-width: 100px;
      padding-left: 70px;
      min-height: 99px;
    }

  }

  .cart-item-image {

    @media(max-width: 1200px) {
      position: absolute;
      left: 0;
      box-shadow: 6px 6px 15px #ddd;
    }

    img {
      width: auto;
      height: auto;
      max-height: 100px;
    }
  }

  .sku-container {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }

  svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  &.cart-row-remove {
    cursor: pointer;
  }

  &.cart-row-total-qty, &.cart-row-total-price, &.cart-row-remove {
    width: 80px;
    text-align: center;
  }

  &.size-cell-container {
    padding-left: 20px;
    display: flex;

    .size-item {
      text-align: center;
      margin-right: 20px;

      &.missing-stock {
        border: 2px solid red;
      }

      .size-name {
        margin-bottom: 5px;
      }

      .stock-qty {
        margin-top: 2px;
        .live-stock-availability {
          display: block;
          background: red;
          color: #FFF;
        }
      }
    }
  }
}

.cart-td-stock-container {
  padding: 0px 0px 0px 30px !important;
}

.cart-cup-row {
  margin-bottom: 20px;
}

.price-padding-cart {
  padding-top: 59px;
}


.total-row-container-cart {
  margin-bottom: 34px;
  height: 45px;
  min-height: 63px;
}

.cart-margin-top {
  margin-top: 6px;
}

.cart-edit-button {
  background-color: #000000a6;
  color: #fff;
  margin: 17px auto 10px;
  width: 100px;
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  cursor: pointer;
}

.cart-remove-button {
  background-color: #e84848a1;
  color: #fff;
  margin: 17px auto 15px;
  width: 100px;
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.color-code-margin-left {
  margin-left: 5px;
}

.color-code {
  display: inline-block !important;
}

.total-price-text-margin {
  margin-top: 30px !important;
}

.cart-simone-header {
  display: none !important;
}

.cart-cup-row {
  display: none;
}

.cart-simone-price {
  display: block !important;
}

.color-cell-container-simone {
  width: 100% !important;
}
