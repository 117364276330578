.cart-search-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 0.9rem;

  .label {
    font-weight: bold;
    margin: 0 10px 0 0;
  }

  input {
    padding: 8px;
    border: 0;
    border-bottom: 1px solid #383839;
    margin: 0 0 0 10px;
  }

  svg {
    max-width: 15px;
    height: auto;
    width: 100%;
  }

  .found-results {
    margin: 0 0 0 10px;
    font-weight: bold;
  }
}

.cart-loader-container {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-table {
  width: 100%;
  font-size: .813rem;
  margin-bottom: 150px;
  border-collapse: separate;
  border-spacing: 0 25px;

  .item-found-in-search {
    td {
      border: 1px solid #383839;
    }
  }

  @media (max-width: 1200px) {
    overflow-x: auto;
    display: block;
  }

}

.cart-empty-msg {
  text-align: center;
  padding: 50px 15px;
}
