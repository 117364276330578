.posters-modal{
  position: fixed;
  //height: 100vh;
  min-height: -webkit-fill-available;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(#ddd, 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  height: 100%;

  .posters-modal-container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 0 35px;

    .slick-next:before, .slick-prev:before {
      color: #383839;
      font-size: 35px;
    }

    .slick-next, .slick-prev {
      width: 35px;
      height: 35px;
    }

    .modal-title {
      color: #383839;
    }

    .close-poster-modal {
      position: absolute;
      right: 20px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background: #383839;
      border-radius: 100%;
      cursor: pointer;
      z-index: 9999;

      svg {
        max-width: 20px;
        width: 100%;

        path {
          fill: #FFF;
        }
      }
    }
  }

  .poster-slider-item {
    padding: 15px;
    box-sizing: border-box;

    .qty-input {
      display: flex;
      flex-direction: column;

      > label {
        margin: 15px 0 0 0;
      }

      .miniature-input-controls {
        margin: 15px 0 0 0;

        .tablet-control-element {
          display: inline-block;
        }

      }

    }

    .product-miniature {
    }
  }
}
