.search-result {
  font-size: .875rem;
  padding: 5px 0;
  margin: 0 0 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    .title {
      font-weight: bold;
    }
  }

  img {
    max-width: 45px;
    height: auto;
    margin-right: 10px;
  }

  .code {
    font-weight: 700;
    color: #383839;
    padding: 0 10px;
  }

  .title {
    padding: 0 10px;
  }

}
