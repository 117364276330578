.account-page {

  .special-label {
    color: #af6767;
  }

  .panel-header {
    background: #FFF;
    color: #383839;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  &.role-admin, &.role-salesman {
    flex-wrap: wrap;

    .account-sidebar {
      max-width: 100%;
      border-right: 0 !important;

      .sidebar-list {
        display: flex;
        margin: 0 0 15px 0;

        li {
          margin: 0 0 0 0;

          a {
            padding: 15px;
          }
        }
      }
    }
  }

  .account-sidebar {
    display: flex;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    //max-width: 200px;
    //border-right: 1px solid #d9d9d9;

    @media (max-width: 768px) {
      max-width: 100%
    }

    .sidebar-list {
      width: 100%;
      display: flex;
      justify-content: center;
    }

  }

  .account-panel {
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
  }

  .account-panel-components {
    .panel-body {
      background: #f8fbff;
    }
  }

}
