.order-details{
  .panel-body{
    .info-table{

      th {
        text-align: center;
      }

      td {
        padding: 15px 30px !important;
        text-align: center;
      }

      img {
        max-width: 150px;
        width: 100%;
        height: auto;
      }
    }
  }
}