.campaign-page {
  display: flex;
  flex-wrap: wrap;

  a {
    display: inline-block;
    width: 100%;

    &.video {
      width: 100%;

      video {
        max-width: 100%;
      }
    }

    img, video {
      width: 100%;
    }

    @media(max-width: 768px) {
      width: 100%;
    }
  }
}
