.multi-grid-wrapper {
  .plus-size-notice {
    margin: 15px 0 0 0;
    text-align: right;
    color: #383839;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .multigrid-table {
    width: 100%;
    margin-bottom: 50px;

    tbody {
      width: 100%;
    }
  }

  .product-meta{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    font-size: 0.875rem;
  }

}

.cta-button-container {
  margin-top: 25px;
  display: block;
  float: right;
  width: 100%;
}
.total-for-all-colors-container {
  margin-top: 25px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: block;
  float: right;
  width: 200px;
  & > p {
    margin-bottom: 0;
  };
  & > p > span {
    font-size: 12px;
    font-weight: 300;
    display: block;
    margin-top: 14px;
  }
}
.reset-grid-button-simone {
  margin-left: auto;
  display: inline-block;
  width: 208px;
  max-width: 270px !important;
  height: 48px;
  font-size: 18px !important;
  line-height: 36px !important;
  padding-top: 5px !important;
  float: right;
}
.order-grid-button-simone {
  display: inline-block;
  width: 208px;
  max-width: 270px !important;
  height: 48px;
  font-size: 18px !important;
  line-height: 36px !important;
  padding-top: 5px !important;
  float: right;
  margin-left: 24px;
}
.color-container {
  padding: 6px 5px 6px 10px;
  border: solid 1px black;
  margin-bottom: 25px;
  display: block;
  width: 170px;
  cursor: pointer;
}
.color-selector-circle-container {
  display: inline-block;
  float: left;
  height: 24px;
}
.color-selector-circle {
  width: 24px;
  border-radius: 12px;
  height: 24px;
}
.color-selector-info{
  display: inline-block;
}
.color-selected-info-text {
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin-left: 6px;
  margin-bottom: 0px;
}
.size-selector-container {
  padding: 20px 30px 30px 20px;
  border: solid #DDDDDD 1px;
  margin-left: 56px;
  min-width: 778px
}
.color-container-div {
  display: inline-block;
  vertical-align: top;
}
.passive-color {
  opacity: 0.4;
}
.color-list-header {
  color: #606060;
  font-size: 14px;
  margin-top: 15px;
}
.simone-main-container {
  padding-top: 24px;
  border-top: 1px solid #D9D9D9;
  overflow: auto;
}
.size-selector-div {
  display: inline-block;
  width: calc(100% - 170px);
}
.size-selector-header {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.size-text {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}
.size-container {
  display: inline-block;
  text-align: center;
  width: calc(((100% - 70px) / 5));
}
.size-container-icon {
  display: inline-block;
  text-align: center;
}
.size-icon {
  width: 35px;
  margin-right: 15px;
}
.cup-row {
  width: 100%;
  height: 65px;
  margin-bottom: 25px;
}
.cup-text {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}
.cup-text-container {
  width: 35px;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
  margin-top: 11px;
}
.size-input {
  width: 72px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  border: solid 1px #DDDDDD;
}
.size-stock-text {
  font-size: 12px;
  color: black;
  display: block;
  text-align: center;
  margin-bottom: 0px;
}
.total-text {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 0;
  width: 65px;
  text-align: center;
}
.passive-color {
  color: #DDDDDD;
}
.total-col-sub-container {
  width: 170px;
  padding-left: 25px;
  display: inline-block;
  border-left: solid 1px #D9D9D9;
  vertical-align: top;
}
.size-selector-sub-container {
  display: inline-block;
  width: calc( 100% - 170px );
}
.total-price-text {
  width: 65px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  padding-top: 10px;
  & > span {
    font-size: 12px;
    font-weight: 300;
    margin-top: 14px;
    display: none;
  }
}
.totat-price-container {
  display: inline-block;
}
.total-number-input-container {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.total-row-container {
  margin-bottom: 25px;
  height: 65px;
}
.total-text-container {
  margin-bottom: 15px;
}
.plus-minus-container {
  margin-top: 6px;
}
.total-minus-cta {
  display: inline-block;
  width: 12px;
}
.total-plus-cta {
  display: inline-block;
  width: 11px;
}
.total-cta-container {
  cursor: pointer;
  height: 100%;
  display: inline-block;
  & > img {
    width: 23px;
  }
}
.plus-cta-icon {
  margin-left: 10px;
  width: 31px;
}
.margin-bottom-zero {
  margin-bottom: 0;
}