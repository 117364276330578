.sample-page-controls {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #383839;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .label {
    font-weight: bold;
  }

  .value {
    font-weight: normal;
    margin: 0 0 0 10px;
  }

  .info-container {
    color: #FFF;
    display: flex;
    align-items: center;
  }

  .actions-container {
    display: flex;
    align-items: center;
  }

  .action-element {
    padding: 0 10px;

    &.view-only-selected {
      &.active {
        svg {
          fill: #e84848;
        }
      }
    }

    svg {
      max-width: 30px;
      width: 100%;
      height: auto;
      fill: #FFF;
      cursor: pointer;
    }
  }

  .checkout-action-element {
    background: #fff;
    color: #383839;
    padding: 10px;
    font-weight: bold;

      svg {
        max-width: 25px;
        width: 100%;
        fill: #383839;
        margin: 0 0 0 6px;
      }
  }
}
