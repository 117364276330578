.filters-overlay {
  position: fixed;
  background: transparent;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.product-filters {
  .item-label {
    font-weight: bold;
    margin: 0 5px 0 0;
  }
}

.filter-values {
  display: none
}

.filters {
  .filter-price  {
    .price-range-values {
      margin: 25px 0 0 0;
      text-align: center;
      font-weight: bold;
    }
  }

  .filters-toggle-button {
    .vamp-button {
      padding: 10px 15px;
      font-weight: bold;
    }
  }

  .filter-actions {
    display: flex;
    justify-content: space-between;
    background: #383839;
    padding: 5px;
    position: fixed;
    width: 100%;

    svg {
      max-width: 22px;
      width: 100%;
      margin: 0;
      fill: #FFF;

      path {
        fill: #FFF;
      }
    }

    .vamp-button  {
      background: none;

      &:hover {
        background: none;
      }
    }
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 0;
  }

  .filters-list-container {
    box-sizing: border-box;
    max-width: 320px;
    min-width: 280px;
    box-shadow: 0 0 10px #000;
    background: #FFF;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    transform: translateX(-400%);
    transition: transform 0.3s ease-in-out;
    z-index: 99;
    color: #000;

    .filters-list-inner {
      padding: 15px;
      overflow-y: auto;
      height: calc(100% - 50px);
      margin: 50px 0 0 0;
    }

    &.show-filters {
      transform: translateX(0);
    }

    ul {
      list-style: none;
    }

    .filters-list {
      padding: 0;
      text-align: left;

      > li {
        margin: 0 0 15px 0;
      }
    }

    .filter-label {
      font-size: 1rem;
      color: #383839;
      font-weight: bold;
      cursor: pointer;
    }

    .filter-values-list {
      padding: 0 0 0 20px;
      max-height: 250px;
      overflow-y: auto;

      li {
        margin: 5px 0;
        padding: 10px 0;
        cursor: pointer;

        &.sizeCode {
          text-transform: uppercase;
        }

        &.sizeName {
          text-transform: uppercase;
        }

        &.active {
          &:before {
            content: '✔';
            margin-right: 3px;
            color: transparent;
            text-shadow: 0 0 0 #383839;
          }
        }
      }
    }
  }
}
