.grid-totals {

  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  > div {
    margin-left: 25px;
  }

  .label {
    font-size: .813rem;
    font-weight: 400;
    color: #000;
    text-transform: lowercase;
    text-align: center;
    padding: 10px 0;
  }

  .value {
    font-size: .813rem;
    font-weight: 400;
    color: #383839;
    text-transform: lowercase;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0;
  }
}


