.fullscreen-loader {
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //height: 100vh;
  min-height: -webkit-fill-available;
  z-index: 99;
  background: rgba(#383839, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  .loader-message {
    text-align: center;
    font-size: 2.5rem;
    color: #FFF;
    font-weight: bold;
    margin-bottom: 50px;
    word-break: break-word;
    display: block;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }

  }

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
}

