.presentation-page {
  .seasons-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    .seasons-item {
      color: #383839;
      text-align: center;
    }

    li {
      width: 100%;
      padding: 25px;
      box-sizing: border-box;
      cursor: pointer;

      @media (max-width: 600px) {
        width: 100%;
      }

      div {
        width: 50%;
        display: inline-block;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      h3 {
        margin: 15px 0;
        font-size: 1.3rem;

        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
