.samples-page {
  .archive-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .product-item {
      box-sizing: border-box;
      width: 20%;
      padding: 0 15px;
      margin: 0 0 35px 0;

      img {
        max-width: 100% !important;
        width: auto;
        margin: auto;
        max-height: 500px;
      }

      @media (max-width: 768px) {
        width: 25%;
      }

      @media (max-width: 600px) {
        width: 33.33%;
      }

      @media (max-width: 560px) {
        width: 50%;
      }
    }
  }
}