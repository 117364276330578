.no-products-found-msg {
  text-align: center;
  padding: 35px 15px;
  margin: 0;
  font-size: 1.1rem;
  color: #383839;
}

.products-list-top-section {
  padding: 15px;

  .sort-by-section {
    display: flex;
    justify-content: flex-end;

    span {
      margin: 0 15px 0 0;
      font-size: 0.9rem;
    }
  }

}

.products-list-component {
  text-align: center;

  .product-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    &.items-per-line-2 {
      > li {
        width: 50% !important;

        @media (max-width: 560px) {
          width: 50% !important;
        }
      }
    }

    &.items-per-line-3 {
      > li {
        width: 33.33% !important;

        @media (max-width: 560px) {
          width: 50% !important;
        }
      }
    }

    &.items-per-line-4 {
      > li {
        width: 25% !important;

        @media (max-width: 560px) {
          width: 50% !important;
        }
      }
    }

    > li {
      box-sizing: border-box;
      width: 33.33%;
      padding: 0 15px;
      margin: 0 0 35px 0;
      cursor: pointer;

      article {
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 0 25px #a0a0a0a3;
        }
      }
    }
  }

  .product-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 18px;
      width: 100%;
      height: auto;
      margin: 0 5px;
    }

    .paginate-next-page,
    .paginate-prev-page {
      cursor: pointer;
    }

    .product-list-pagination {
      list-style: none;
      padding: 0;
      margin: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 10px;
        padding: 13px;
        background: #FFF;
        color: #999;
        border: 1px solid #999;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        cursor: pointer;
        border-radius: 50%;

        &.active {
          background: #383839;
          color: #FFF;
        }
      }
    }
  }
}

