.admin-section {
  margin: 0 0 0 0;

  header {
    background: #383839;
    color: #FFF;
    padding: 15px;
    margin: 0 0 15px 0;
  }

  .admin-section-content {
    .form-element {
      display: flex;
      width: 100%;

      input {
        max-width: 100px;
      }

    }
  }

}

.admin-header-clickable {
  cursor: pointer;
}
