.product-item {
  text-align: center;

  .product-image {
  }

  .product-in-sale-bubble {
    position: absolute;
    right: 0;
    top: 0;
    color: #e13c5d;
    padding: 5px;
  }

  .delivery-date {
    font-weight: bold;
  }

  > a {
    text-decoration: none;
  }

  .item-name {
    color: #7d7d7d;
  }

  .size-attributes {
    display: flex;
    justify-content: center;

    @media (max-width: 560px) {
      flex-direction: column;
    }

    span {
      padding: 0 5px;
      border-right: 1px solid #383839;

      @media (max-width: 560px) {
        border-right: 0;
        padding: 1px 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  .plus-size-icon {
    position: absolute;
    left: 15px;
    top: 15px;

    svg {
      max-width: 60px;
      width: 100%;
      height: auto;

      @media (max-width: 640px) {
        max-width: 35px;
      }

    }
  }

  .item-price {
    font-size: 1rem;
    font-weight: bold;

    span {
      margin: 0 4px;
    }

    .regular-price {
      color: #919191;
      text-decoration: line-through;
    }

  }

  img {
    width: 100%;
    height: auto;
  }

  .product-meta {
    padding: 15px 0;
    color: #383839;
    font-size: 1rem;

    > div {
      margin: 10px 0 0 0;
    }

    .product-code {
      font-weight: bold;
    }

  }

  .extra-meta {
    font-size: 0.875rem;
    text-align: center;

    > div {
      margin: 0 0 10px 0;

      &:last-child {
        margin: 0;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      overflow: auto;

      li {
        padding: 0 5px;
      }

    }

    .colors {
      .color-name {
        display: none;
      }

      .color-hex {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid rgba(217, 217, 217, .8);
      }
    }
  }
}

.web-only-svg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 90px !important;
  @media (max-width: 900px) {
    width: 68px !important;
  }
  @media (max-width: 680px) {
    width: 50px !important;
  }
  @media (max-width: 560px) {
    width: 76px !important;
    left: 0px;
    top: 0px;
  }
  @media (max-width: 480px) {
    width: 60px !important;
    left: 0px;
    top: 0px;
  }
}

//Coming soon
.product-item {
  .delivery-date {
    position: absolute;
    left: 0px;
    top: 22px;
    padding: 5px;
    font-size: 0.8rem;
    transform: rotate(-45deg);
    border-top: 1px solid #383839;
    border-bottom: 1px solid #383839;
  }
}
