.fancybox-video {
  width: 100%;
  max-width: 100%;
}

.product-images-container {
  padding: 0 45px;

  .owl-nav {
    .owl-prev, .owl-next {
      margin: 10px !important;

      &:focus {
        outline: none !important;
      }

      &:hover {
        background: transparent !important;
        color: #383839 !important;
        outline: none !important;

        button {
          outline: none !important;
        }
      }

      span {
        font-size: 4rem;

        &:focus {
          outline: none !important;
        }
      }
    }

    .owl-prev {
      position: absolute;
      left: -45px;
      top: 50%;
      transform: translateY(-50%);
    }

    .owl-next {
      position: absolute;
      right: -45px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .owl-dots {
    button {
      outline: none;
    }

    span {
      width: 10px !important;
      height: 10px !important;
    }

    .owl-dot.active {
      span {
        background: #383839 !important;
      }
    }
  }

  .carousel-item {
    img, video {
      max-width: 800px;
      height: auto;
      width: 100%;
    }
  }

  video, img {
    &:focus {
      outline: none;
      border: 0;
    }
  }
}
