.cart-page-controls-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #383839;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .action-element {
    padding: 0 10px;

    svg {
      max-width: 25px;
      width: 100%;
      height: auto;
      fill: #FFF;
      cursor: pointer;
    }
  }

  .checkout-action-element {
    background: #fff;
    color: #383839;
    padding: 10px;
    font-weight: bold;

    svg {
      max-width: 25px;
      width: 100%;
      fill: #383839;
      margin: 0 0 0 6px;
    }
  }

  .right-controls {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .left-controls {
    display: flex;
    align-items: center;

    .vamp-button {
      margin-right: 15px;
    }
  }

  .checkout-button {
    max-width: 250px;
    width: 100%;
    background: #383839;
    color: #FFF;

    &:hover {
      background: #FFF;
      color: #383839;
    }
  }

}
