.sidebar-list {
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > li {
      width: 33.33%;
      display: flex;
      align-items: center;
      margin-right: 0;
    }

  }

  > li {
    margin-bottom: 15px;

    @media (max-width: 768px) {
      margin: 0 0 10px 0!important;
      width: 50%;
      a, span {
        padding: 10px !important;
      }
    }
  }
}
