.admin-menu-editor {
  .menu-editor {
    display: flex;

    .rst__collapseButton {
      display: none;
    }

    .rst__rowTitleWithSubtitle, .rst__rowSubtitle {
      height: 25px;
    }

    .rst__tree {
      width: 80%;
      height: 100vh;
    }

    .menu-actions {
      width: 20%;
      padding-left: 25px;
    }

    .add-node-container {
      .form-group {
        font-size: 14px;
        label {
          display: block;
        }

        input {
          width: 100%;
          padding: 5px;
        }
      }
    }

  }
}