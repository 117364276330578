.sizes-list-component {
  display: flex;
  justify-content: center;

  ul {
    padding: 0;
    margin: auto;
    display: flex;
    list-style: none;
    max-width: 320px;
    overflow-x: auto;

    li {
      display: flex;
      align-items: center;
      padding: 0 5px 0 0;
    }
  }
}
