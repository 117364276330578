.cart-grid-header-row {
  background: transparent;
}

.cart-table-header {
  min-width: 35px;

  svg {
    width: 35px;
    max-width: 35px;
    height: auto;
  }

  &.cart-header-product {
    width: 90px;
  }

  span {
    border-left: 0;
    border-right: 0;
    font-size: 18px;
    font-weight: bold;
  }
}

.cart-header-color {
  width: 170px;
  text-align: center;
}
.cart-header-price {
  width: 160px;
}
.cart-header-size {
  padding-left: 30px;
}
