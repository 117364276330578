.families-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    width: 33.33%;
    margin: 0 0 25px 0;

    @media (max-width: 768px) {
      width: 50%;
    }

  }
}
