.search-component-wrapper {
  display: flex;
  align-items: center;

  .barcode-scanner-button {
    margin-left: 15px;

    svg {
      max-width: 35px;
      width: 100%;
    }
  }

}

.search-component {
  box-sizing: border-box;
  position: relative;
  width: 100%;

  .lds-ellipsis{
    position: absolute;
    right: 80px;
    top: 3px;
  }

  .clear-icon {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 2;
    cursor: pointer;

    svg {
      max-width: 20px;
    }

  }

  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 24px rgba(0,0,0,.16);
    z-index: 99;
    list-style: none;
    box-sizing: border-box;
    margin: 0;

    .code {
      padding-right: 15px;
      font-weight: bold;
      color: #383839;
    }

    .search-result {
      font-size: 14px;
      color: #999;
      transition: color 0.3s ease-in-out;
      cursor: pointer;
      padding: 5px 0;
      margin-bottom: 5px;

      &:hover {
        color: #383839;
      }
    }
  }
}

.search-input {
  position: relative;
  z-index: 0;

  .search-icon  {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  input {
    display: block;
    padding: 8px 40px 8px 45px;
    border: 0;
    border-bottom: 1px solid #383839;
    box-sizing: border-box;
    font-size: 1rem;
    width: 100%;
  }
}
