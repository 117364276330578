.back-button {
  color: #919191;
  font-size: 1.2rem;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;

  svg {
    margin-right: 5px;
    fill: #919191;
    max-width: 25px;
    width: 100%;
    height: auto;
  }

}

.action-button {
  color: #919191;
  font-size: 1rem;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    fill: #919191;
    max-width: 25px;
    width: 100%;
    height: auto;
  }

  span {
    line-height: 1;
  }

  &:focus {
    outline: none;
  }
}

.vamp-button {
  box-sizing: border-box;
  background: #fff;
  color: #383839;
  border: 1px solid #383839;
  max-width: 250px;
  text-align: center;
  padding: 8px 15px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;

  &.vamp-red {
    background: red;
    color: #FFF;
  }

  &.vamp-green {
    background: green;
    color: #FFF;
  }

  svg {
    max-width: 20px;
    width: 100%;
    height: auto;
    margin-left: 20px;
  }

  &:hover {
    background: #383839;
    color: #fff;
  }

  &.vamp-button-blue {
    background: #383839;
    color: #FFF;
    border: 1px solid #383839;

    &:hover {
      background: #FFF;
      color: #383839;
    }
  }

}
