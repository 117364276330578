.entry-message-underline {
  text-decoration: underline;
}

.return-password-form {
  margin: 15px 0;
  font-size: 0.875rem;
  text-align: center;

  header {
    h4 {
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: bold;
    }
  }

  .err-message {
    color: red;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  button {
    border: 0;
    background: #383839;
    color: #FFF;
    font-size: 0.9rem;
    padding: 5px;
    cursor: pointer;
  }
}