.cart-container {
  width: 100%;
  margin: auto;
}

.edit-order-message {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
