.thank-you-page {
  text-align: center;
  padding: 50px 0;

  .header-message {
    svg {
      max-width: 150px;
      height: auto;
      width: 100%;
    }
  }

  .result-msg {
    &.notice-msg {
      color: #b10d0d;
    }

    svg {
      max-width: 60px;
      height: auto;
      width: 100%;
    }
  }

  .order-summary-table {
    text-align: center;
    max-width: 600px;
    margin: 50px auto;

    h3 {
      font-size: 1.5rem;
      padding: 0 0 5px 0;
      margin: 0 0 5px 0;
    }

    table {
      width: 100%;

      th {
        background: #383839;
        color: #FFF;
      }

    }
  }
}
