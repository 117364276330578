.notices-presenter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #383839;
  color: #FFF;
  padding: 10px;
  text-align: center;
  z-index: 999999;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}