.form-element {
  margin: 0 0 15px 0;
  width: 50%;
  padding: 0 15px;
  box-sizing: border-box;

  &.checkbox-element {
    display: flex;
    align-items: center;
  }

  &.required {
    label {
      &:after {
        content: '*';
        color: red;
      }
    }
  }

  .errors {
    color: red;
    font-size: 0.875rem;
  }

  label {
    max-width: 180px;
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
    color: #383839;
    min-width: 80px;
    font-size: 0.875rem;
  }

  input, select {
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 8px;
    font-size: .875rem;
    box-sizing: border-box;
  }
}
