.customer-list-item {
  text-align: center;
  font-size: 0.9rem;

  .customer-item-box {
    min-height: 100px;
    padding: 15px;
    box-shadow: 3px 4px 18px rgba(0,0,0,.15);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    svg {
      margin-bottom: 5px;
    }

    > div {
      margin-bottom: 5px;
      word-break: break-all;
    }

    .customer-name {
      font-weight: 700;
      color: #383839;
      min-height: 40px;
    }

    .select-button {
      background: #383839;
      color: #fff;
      width: 100%;
      padding: 10px;
      font-weight: 700;
      text-align: center;
      font-size: .8rem;
      margin: 15px 0 0 0;
      box-sizing: border-box;
      cursor: pointer;
    }

  }
}
